import React, { useState } from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      overlayStyle={{ zIndex: "99999999999" }}
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const MinMaxSlider = ({ piece, rotate }) => {
  // const [value, setValue] = useState(piece.rotation);

  const handleChange = (val) => {
    rotate(piece, val);
  };

  return (
    <Slider
      min={0}
      max={359}
      value={piece.rotation}
      handle={handle}
      onChange={handleChange}
    />
  );
};

export default MinMaxSlider;

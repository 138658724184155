import React, { Component } from "react";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";
import { BoardPiece } from "../../app/views/dashboard/dashboard2/BoardPiece";
import { Loading } from "@gull";
import { Spinner } from "react-bootstrap";
import { Box } from "../../app/views/components/elements";
class DropDownMenu extends Component {
  state = {
    open: false,
  };

  onItemClick = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  renderLevels = (items) => {
    return items.map((item, i) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.sub)}
          </DropDownMenuItem>
        );
      } else {
        return (
          <li key={i}>
            <div
              className="piece-template"
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                padding: "20px",
                border: "1px solid #65b1e2",
                borderRadius: "4px",
                margin: "30px",
                backgroundColor: "#e7ebec",
                boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
              }}
            >
              {/* <img alt={item.name} src={item.thumbnail}></img> */}
              {this.props.toolbox && this.props.toolbox.length > 0 && (
                <BoardPiece
                  piece={
                    this.props.toolbox.filter((t) => t.name === item.name)[0]
                  }
                />
              )}
            </div>
          </li>
          // <li
          //   key={i}
          //   className={classList({
          //     "nav-item": true,
          //     open: this.state.open,
          //   })}
          //   onClick={this.props.closeSecSidenav}
          // >
          //   <Link to={item.path}>
          //     <i className={`nav-icon ${item.icon}`}></i>
          //     <span className="item-name">{item.name}</span>
          //   </Link>
          // </li>
        );
      }
    });
  };

  render() {
    if (this.props.toolbox && this.props.toolbox.length > 0) {
      return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
    } else {
      return (
        <Box width="100%" justifyContent="center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Box>
      );
    }
  }
}

export default DropDownMenu;

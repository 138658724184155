import {
  UPDATE_BOARD,
  UPDATE_BOARD_ITEMS,
  UPDATE_BOARD_COLOR,
  UPDATE_INVENTORY
} from "../actions/BoardActions";

const initialState = {
  board: null,
  boardColor: null,
  boardColorOptions: [],
  boardOptions: [],
  items: [],
  toolbox: [],

}
// const initialState = {
//   board: {
//     sku: 'B-000001',
//     name:'Buzyboard (60 x 60 cm)',
//     optDisplay: '60 x 60 cm (CA $150.63)',
//     src: "/assets/images/boards/board1.png",
//     height: "600px",
//     width: "600px",
//     price: 150.63
//   },
//   boardColor: {
//     id: 3,
//     color: "#97989d",
//     name: 'Grey'
//   },
//   boardColorOptions: [
//     {
//       id: 1,
//       color: "#e8b07e",
//       name: 'Wood'
//     },
//     {
//       id: 2,
//       color: "#f5f5cf",
//       name: 'Yellow'
//     },
//     {
//       id: 3,
//       color: "#97989d",
//       name: 'Grey'
//     },
//     {
//       id: 4,
//       color: "#f5cee0",
//       name: 'Pink'
//     },
//     {
//       id: 5,
//       color: "#a2e3a3",
//       name: 'Green'
//     },
//     {
//       id: 6,
//       color: "#9bc6eb",
//       name: 'Blue'
//     },
//   ],
//   boardOptions: [
//     {
//       sku: 'B-000001',
//       name:'Buzyboard (60 x 60 cm)',
//       optDisplay: '60 x 60 cm (CA $150.63)',
//       src: "/assets/images/boards/board1.png",
//       height: "600px",
//       width: "600px",
//       price: 150.63
//     },
//     {
//       sku: 'B-000002',
//       name:'Buzyboard (60 x 80 cm)',
//       optDisplay: '60 x 80 cm (CA $199.79)',
//       src: "/assets/images/boards/board1.png",
//       height: "600px",
//       width: "800px",
//       price: 199.79
//     },
//   ],
//   items: [
//   ],
//   toolbox: [{
//         sku: '000001',
//         type: 'template',
//         name: "lock1",
//         width: "100px",
//         height: "100px",
//         image: '/assets/images/toolbox/locks/lock1.png',
//         top: 0,
//         left: 0,
//         category: 'locks',
//         price: 1.99,
//         selectedOption: null,
//         options: {
//           red: {
//             color: '#d40f1c',
//             image: '/assets/images/toolbox/locks/lock1_red.png'
//           },
//           blue: {
//             color: '#2c24e8',
//             image: '/assets/images/toolbox/locks/lock1_blue.png'
//           },
//           green: {
//             color: '#81b184',
//             image: '/assets/images/toolbox/locks/lock1_green.png'
//           }
//         }
//     },
//     {
//         sku: '000002',
//         type: 'template',
//         name: "lock2",
//         width: "356px",
//         height: "114px",
//         image: '/assets/images/toolbox/gears/gears1.png',
//         top: 100,
//         left: 100,
//         category: 'locks',
//         price: 1.99,
//         selectedOption: null,
//         colorOptions: null,
//         rotation: 0
//       },
//     {
//       sku: '000003',
//         type: 'template',
//         name: "lock3",
//         width: "100px",
//         height: "100px",
//         image: '/assets/images/toolbox/locks/lock3.png',
//         top: 100,
//         left: 100,
//         category: 'locks',
//         price: 1.99,
//         selectedOption: null,
//         colorOptions: null,
//         rotation: 0
//       },
//       {
//         sku: '000004',
//         type: 'template',
//         name: "knob1",
//         width: "100px",
//         height: "100px",
//         image: '/assets/images/toolbox/knobs/knob1.png',
//         top: 100,
//         left: 100,
//         category: 'knobs',
//         price: 1.99,
//         selectedOption: null,
//         colorOptions: null,
//         rotation: 0
//       },
//       {
//         sku: '000005',
//         type: 'template',
//         name: "knob2",
//         width: "100px",
//         height: "100px",
//         image: '/assets/images/toolbox/knobs/knob2.png',
//         top: 100,
//         left: 100,
//         category: 'knobs',
//         price: 1.99,
//         selectedOption: null,
//         colorOptions: null,
//         rotation: 0
//       },
//       {
//         sku: '000006',
//         type: 'template',
//         name: "zipper1",
//         width: "100px",
//         height: "100px",
//         image: '/assets/images/toolbox/zippers/zipper1.png',
//         top: 100,
//         left: 100,
//         category: 'zippers',
//         price: 1.99,
//         selectedOption: null,
//         colorOptions: null,
//         rotation: 0
//       }
//     ]
// };

const BoardReducer = (state = initialState, action) => {



  switch (action.type) {
    case UPDATE_BOARD:
      return {
        ...state,
        board: { ...action.data }
      };
    case UPDATE_BOARD_ITEMS:
      return {
        ...state,
        items: [...action.data ]
      };
      case UPDATE_BOARD_COLOR:
      return {
        ...state,
        boardColor: { ...action.data }
      };
      case UPDATE_INVENTORY:

      const {pieces, boards, boardColours} = action.payload;
      const templatePieces = pieces.map(p => {
        p.type = 'template';
        return p;
      })
      console.log(action.data)

        return {
          ...state,
          board: { ...boards[0]},
          boardColor: {...boardColours[0]},
          boardColorOptions: [...boardColours],
          boardOptions: [...boards],
          toolbox: [...templatePieces]
        };
    default:
      return { ...state };
  }
};

export default BoardReducer;
const layout2Settings = {
  leftSidebar: {
    theme: "sidebar-babyblue",
    open: false, 
    secondaryNavOpen: false
  },
  header: {
    show: true,
    showMenuToggle: false,
  },
  searchBox: {
    open: false
  },
  secondarySidebar: { show: false },
};

export default layout2Settings;

import React, { useState, useEffect } from "react";
import { Box } from "../../components/elements";
import { Button } from "react-bootstrap";
function PieceOptionSelector({ piece, applyPieceOption }) {
  const handleChange = (newOption) => {
    if (applyPieceOption) {
      applyPieceOption(piece, newOption);
    }
  };

  const handleBlur = () => {};

  const renderOptions = () => {
    if (!piece.options || piece.options.length === 0) {
      return null;
    }

    var optionKeys = piece.options.map((o) => o.key);

    console.log(optionKeys);

    return piece.options.map((option) => {
      if (option.colour) {
        return (
          <Button
            className="btn btn-icon btn-rounded mr-2"
            key={option.key}
            style={{
              marginRight: "10px",
              width: "30px",
              height: "30px",
              backgroundColor: option.colour,
            }}
            onClick={() => {
              handleChange(option.key);
            }}
          ></Button>
        );
      } else {
        return (
          <Button
            className="btn btn-icon m-1 p-1"
            key={option.key}
            variant="outline-primary"
            style={{
              minWidth: "30px",
              height: "30px",
              textAlign: "center",
              padding: 0,
              //backgroundColor: piece.options[opt].color,
            }}
            onClick={() => {
              handleChange(option.key);
            }}
          >
            {option.key}
          </Button>
        );
      }
    });
  };

  return (
    <Box flexDirection="column" mb="10px">
      <label>{piece.options[0].colour ? "Select Colour" : "Select"}</label>
      <Box flexWrap="wrap" maxWidth="200px">
        {renderOptions()}
      </Box>
    </Box>
  );
}

export { PieceOptionSelector };

import "../fake-db";
import React, { Suspense, useEffect } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
// import { DndProvider } from "react-dnd";
import { DndProvider } from "react-dnd-multi-backend";
// import { HTML5Backend } from "react-dnd-html5-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "../app/views/styles";
import { loadStripe } from "@stripe/stripe-js/pure";

function App() {
  useEffect(() => {
    const initialize = async () => {
      // Stripe.js will not be loaded until `loadStripe` is called
      const stripe = await loadStripe(
        // "pk_test_51IVpawKF24Zmtmx7AVKfn138K3BWbbDaU00TDRCcAylEs3YJbrzVnpaF6tHcKuVNC90E1GRpoSx4wJERTgzVi3K500vmbsnJst"
        "pk_live_51IVpawKF24Zmtmx7JTRWLpHbUnWd9g2TAw4KACgC257lZXb8g1p8sLlNU6tQpublzhenVBA8XbK9FZr9QwN0U8ev00CfMJ5eR0"
      );
      window.stripe = stripe;
    };
    initialize();
  }, []);

  return (
    <AppContext.Provider value={{ routes }}>
      <ThemeProvider theme={theme}>
        <DndProvider options={HTML5toTouch}>
          <Provider store={Store}>
            <Auth>
              <Suspense fallback={<Loading></Loading>}>
                <Router history={history}>{renderRoutes(RootRoutes)}</Router>
              </Suspense>
            </Auth>
          </Provider>
        </DndProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;

import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));

const Dashboard2 = lazy(() => import("./dashboard2/Dashboard2"));

const OrderDashboard = lazy(() => import("./dashboard2/OrderDashboard"));

const Shop = lazy(() => import("./shop/Shop"));

const AboutUs = lazy(() => import("./about-us/AboutUs"));

const ThankYou = lazy(() => import("./transactions/ThankYou"));

const Failed = lazy(() => import("./transactions/Failed"));

const dashboardRoutes = [
  {
    path: "/home",
    component: Dashboard1,
    auth: authRoles.guest
  },
  {
    path: "/shop",
    component: Shop,
    auth: authRoles.guest
  },
  {
    path: "/build",
    component: Dashboard2,
    auth: authRoles.guest
  },
  {
    path: "/order",
    component: OrderDashboard,
    auth: authRoles.guest
  },
  {
    path: "/about-us",
    component: AboutUs,
    auth: authRoles.guest
  },
  {
    path: "/success",
    component: ThankYou,
    auth: authRoles.guest
  },
  {
    path: "/fail",
    component: Failed,
    auth: authRoles.guest
  }
];

export default dashboardRoutes;

import React from "react";
import { Box } from "../../../views/components/elements";
import { useDrag, DragPreviewImage } from "react-dnd";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import PopoverStickOnHover from "../../components/compound/PopoverStickOnHover";
import { PieceOptionSelector } from "./PieceOptionSelector";
import { PieceColorSelector } from "./PieceColorSelector";
import { usePreview } from "react-dnd-preview";
import { useDragLayer } from "react-dnd";
import MinMaxSlider from "../../../views/ui-kits/slider/MinMaxSlider";
import { hexToCSSFilter } from "hex-to-css-filter";

function DragLayerComponent(props) {
  const collectedProps = useDragLayer((monitor) => console.log(monitor));
  console.log("a:", collectedProps);
  return (
    <div>
      <Box height="200px" width="200px" bg="pink"></Box>
    </div>
  );
}

const BoardPiece = ({
  piece,
  removePiece = null,
  applyPieceOption = null,
  applyPieceColourOption = null,
  rotatePiece = null,
}) => {
  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      type: "card",
      piece: piece,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function getFileWithSuffix(filename, string) {
    var dotIndex = filename.lastIndexOf(".");
    if (dotIndex == -1) return filename + string;
    else
      return (
        filename.substring(0, dotIndex) + string + filename.substring(dotIndex)
      );
  }

  const getImage = () => {
    var suffix = "";

    if (piece.selectedColourOption) {
      suffix = "_black";
    }

    if (piece.selectedOption) {
      const selectedPieceOption = piece.options.filter(
        (opt) => opt.key === piece.selectedOption
      )[0];
      return getFileWithSuffix(selectedPieceOption.image, suffix);
    }
    return getFileWithSuffix(piece.image, suffix);
  };

  const isTemplate = piece.type === "template";

  const getTemplateStyle = () => {
    return {
      position: "relative",
      backgroundImage: `url('${piece.image}')`,
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    };
  };

  const getBoardPieceStyle = () => {
    let filter = "none";

    if (piece.selectedColourOption) {
      const hexColour = piece.colourOptions.filter(
        (opt) => opt.key === piece.selectedColourOption
      )[0].colour;
      const cssFilter = hexToCSSFilter(hexColour);
      filter = cssFilter.filter.replace(";", "");
    }

    return {
      position: "absolute",
      left: `${piece.left}px`,
      top: `${piece.top}px`,
      backgroundImage: `url('${getImage()}')`,
      backgroundSize: "100% 100%",
      transform: `rotate(${piece.rotation}deg)`,
      filter,
      // border: "1px solid red",
    };
  };

  const draggingPieceStyle = {
    position: "absolute",
    // left: `${piece.left}px`,
    // top: `${piece.top}px`,
    // backgroundImage: `url('${getImage()}')`,
    // backgroundSize: "100% 100%",
    // border: "1px solid red",
    width: piece.width,
    height: piece.height,
  };

  const popover = (
    <Popover.Content>
      <Box flexDirection="column">
        {piece.options && piece.options.length > 0 && (
          <PieceOptionSelector
            piece={piece}
            applyPieceOption={applyPieceOption}
          />
        )}
        {piece.colourOptions && piece.colourOptions.length > 0 && (
          <PieceColorSelector
            piece={piece}
            applyPieceColourOption={applyPieceColourOption}
          />
        )}
        <Box width="100%" flexDirection="column" mb="20px" mt="20px">
          <p style={{ marginBottom: "2px" }}>Rotate</p>
          <MinMaxSlider piece={piece} rotate={rotatePiece}></MinMaxSlider>
        </Box>
        <Button
          onClick={() => {
            if (removePiece) {
              removePiece(piece);
            }
          }}
        >
          <span>{`Remove`}</span>
          <i style={{ marginLeft: "10px" }} className="i-Close"></i>
        </Button>
      </Box>
    </Popover.Content>
  );

  return (
    <PopoverStickOnHover
      component={popover}
      placement="right"
      onMouseEnter={() => {}}
      delay={200}
      disabled={!removePiece || isDragging}
    >
      <Box
        ref={dragRef}
        className={"piece"}
        style={isTemplate ? getTemplateStyle() : getBoardPieceStyle()}
        width={isTemplate ? "100px" : piece.width}
        height={isTemplate ? "100px" : piece.height}
      ></Box>

      <DragPreviewImage connect={preview} src="/drag.png" />
      {/* <MyPreview /> */}
      {/* <DragLayerComponent /> */}
    </PopoverStickOnHover>
  );
};
export { BoardPiece };

export const apiServer = 'https://api.buzyboard.ca/api'
//export const apiServer = 'https://localhost:5001/api'


export const formatMoney = (number, decPlaces, decSep, thouSep) => {
    decSep = typeof decSep === 'undefined' ? '.' : decSep;
    thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
    var sign = number < 0 ? '-' : '';
    var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
    var j = (j = i.length) > 3 ? j % 3 : 0;
  
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : '') +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : '')
    );
  };
import axios from 'axios';
import { apiServer } from '../../config'

export const UPDATE_BOARD = "UPDATE_BOARD";
export const UPDATE_BOARD_ITEMS = "UPDATE_BOARD_ITEMS";
export const UPDATE_BOARD_COLOR = "UPDATE_BOARD_COLOR";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";

export const updateBoard = data => dispatch => {
  dispatch({
    type: UPDATE_BOARD,
    data: data
  });
};

export const updateBoardItems = data => dispatch => {
  dispatch({
    type: UPDATE_BOARD_ITEMS,
    data: data
  });
};

export const updateBoardColor = data => dispatch => {
  dispatch({
    type: UPDATE_BOARD_COLOR,
    data: data
  });
};

export const setupInventory = () => dispatch => {
  console.log('setup Inventory action')
  axios.get(`${apiServer}/inventory`).then(res => {
    dispatch({
      type: UPDATE_INVENTORY,
      payload: res.data
    });
  });
};
import { createMuiTheme } from '@material-ui/core';

/* eslint-disable prefer-destructuring */
const colors = {
  white: '#fff',
  black: '#000',
  text: '#333',
  blue: '#2f80da',
  overlay: 'rgba(0,0,0,.4)',
  modalBg: 'rgba(255,255,255,1)',
  green: '#066F29',

  primary: ['#C59E2E'],

  greys: [
    '#F7FAFC',
    '#EDF2F7',
    '#E2E8F0',
    '#CBD5E0',
    '#A0AEC0',
    '#718096',
    '#4A5568',
    '#2D3748',
    '#1A202C',
    '#d3d3d345',
    '#C7CEDB',
    '#696969'
  ],

  blues: [
    '#003e6b',
    '#0a558c',
    '#0f609b',
    '#186faf',
    '#2680c2',
    '#4098d7',
    '#62b0e8',
    '#84c5f4',
    '#b6e0fe',
    '#dceefb'
  ],

  reds: [
    '#FFF5F5',
    '#FED7D7',
    '#FEB2B2',
    '#FC8181',
    '#F56565',
    '#E53E3E',
    '#C53030',
    '#9B2C2C',
    '#742A2A'
  ],

  yellows: ['#f3c20a']
};

/*
experimental

colors defined in palette
palette would be referenced by colors variable

const colors2 = {
  bodytext: '',
  background: '',
  primary: '',
  seconday: '',
  overlay: '',
  grays,

  state: {
    error: '',
    success: '',
    warning: '',
  }
}
*/

const zIndices = {
  hide: -1,
  none: 0,
  navbar: 90,
  modal: 100
};
const breakpoints = [520, 768, 992, 1200, 1720].map(n => `${n}px`);

const sizes = [500, 720, 940, 1140, 1640].map(n => `${n}px`);

const borders = [
  'none',
  `1px solid ${colors.greys[0]}`,
  `1px solid ${colors.greys[2]}`,
  `1px solid ${colors.greys[3]}`,
  `1px solid ${colors.primary[0]}`,
  `2px solid ${colors.primary[0]}`,
  `1.5px solid ${colors.greys[0]}`,
  `1px solid ${colors.greys[7]}`,
  `1px solid ${colors.blue}`
];

borders.error = `1px solid ${colors.reds[5]}`;

const space = [0, 4, 8, 16, 24, 32, 40, 48, 64, 80, 96, 112, 128].map(
  n => `${n / 10}rem`
);

const shadows = [
  'none',
  '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '0 25px 50px -12px rgba(0, 0, 0, 0.25)'
];

shadows.outline = '0 0 0 3px rgba(66, 153, 225, 0.6)';
shadows.inner = 'inset 0 2px 4px 0 rgba(0,0,0,0.06)';

// TYPOGRAPHY

const fonts = {
  title: "'Roboto', 'Arial', sans-serif;",
  body: "'Roboto', 'Arial', sans-serif;",
  sports: "'Roboto', 'Arial', sans-serif;"
};

const fontSizes = [10, 12, 14, 16, 20, 24, 32, 48, 64, 72].map(
  n => `${n / 10}rem`
);

const fontWeights = [300, 400, 600, 700];

fontWeights.light = fontWeights[0];
fontWeights.base = fontWeights[1];
fontWeights.bold = fontWeights[2];

const lineHeights = {
  short: 1.3,
  body: 1.7,
  tall: 2.4,
  title: 2.25
};

const letterSpacings = {
  base: '1px',
  wide: '1.3px'
};

const headingStyles = [
  // {
  //   fontSize: fontSizes[6],
  //   fontWeight: fontWeights[2],
  //   lineHeight: lineHeights.title,
  // },
  // {
  //   fontSize: fontSizes[5],
  //   fontWeight: fontWeights[2],
  //   lineHeight: lineHeights.title,
  // },
  // {
  //   fontSize: fontSizes[4],
  //   fontWeight: fontWeights[2],
  //   lineHeight: lineHeights.title,
  // },
  // {
  //   fontSize: fontSizes[3],
  //   fontWeight: fontWeights[2],
  //   lineHeight: lineHeights.title,
  // },
];

const textStyles = {
  caps: {
    textTransform: 'uppercase'
  }
};

const radii = [0, '3px', '6px', '10px', '999px'];

radii.cardImg = '6px 6px 0 0';
radii.searchBar = '10px 0px 0px 10px';
radii.searchBarButton = '0 10px 10px 0';
radii.rounded = '15px';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#696969',
      light: '#C7CEDB',
      dark: '#3E3E3E',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#F3C20A',
      light: '#fff04d',
      dark: '#EFA00B',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#FF220C',
      light: '#ff4e3c',
      dark: '#b21708'
    },
    warning: {
      main: '#EFA00B',
      light: '#f2b33b',
      dark: '#a77007'
    },
    success: {
      main: '#A7C957',
      light: '#b8d378',
      dark: '#748c3c'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 17
  }
});

const muiThemeReversed = createMuiTheme({
  palette: {
    primary: {
      main: '#F3C20A',
      light: '#fff04d',
      dark: '#EFA00B',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#696969',
      light: '#C7CEDB',
      dark: '#3E3E3E',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#FF220C',
      light: '#ff4e3c',
      dark: '#b21708'
    },
    warning: {
      main: '#EFA00B',
      light: '#f2b33b',
      dark: '#a77007'
    },
    success: {
      main: '#A7C957',
      light: '#b8d378',
      dark: '#748c3c'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 17
  }
});

export const theme = {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  headingStyles,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
  textStyles,
  zIndices,
  muiTheme,
  muiThemeReversed
};
